html {
  font-size: 10px;
}

/* testing stuff */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
  padding: 0;
  font-size: 14px
}

* {
  box-sizing: border-box;
}

.container {
  min-height: 100vh !important;
}

.receipt-main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white none repeat scroll 0 0;
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  padding: 40px 30px !important;
  position: relative;
  /* box-shadow: 0 1px 1px #062a304f; */
  color: #22C1B7;
}

.receipt-main p {
  color: #333333;
  line-height: 1.42857;
}

.foot_ {
  border: 1px solid black !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 0px !important;
}

.footer__b {
  font-size: 18px;
  border: 1px solid black !important;
  border-left: 0px !important;
  border-right: 0px !important;
}

.receipt-footer h1 {
  font-size: 15px;
  font-weight: 400 !important;
  margin: 0 !important;
}

.receipt-main::after {
  /* background: #414143 none repeat scroll 0 0; */
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: -13px;
}

.receipt-main thead {
  background: rgba(128, 128, 128, 0.32) none repeat scroll 0 0;
}

.receipt-main thead th {
  border: none;
  color: black;
}

.receipt-right h5 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 0px 0;
}

.receipt-right p {
  font-size: 12px;
  margin: 0px;
}

.receipt-right p i {
  text-align: center;
  width: 18px;
}

.receipt-main td {
  padding: 9px 20px !important;
  border: none;
}

table {
  border-collapse: collapse;
}

td {
  border: none !important;
}

th {
  border: none !important;
}

.receipt-main th {
  padding: 13px 20px !important;
  border: none;
}

.receipt-main td {
  font-size: 13px;
  font-weight: initial !important;
  border: none;
}

.receipt-main td p:last-child {
  margin: 0;
  border: none;
  padding: 0;
}

hr {
  border: 0;
  clear: both;
  display: block;
  background-color: black;
  height: 1px;
}

.receipt-main td h2 {
  font-size: 20px;
  font-weight: 900;
  margin: 0;
  border: none;
  text-transform: uppercase;
}

.receipt-header-mid .receipt-left h1 {
  font-weight: 100;
  margin: 34px 0 0;
  text-align: right;
  text-transform: uppercase;
}

.receipt-header-mid {
  margin: 4px 0;
  overflow: hidden;
}

.receipt-left {
  align-items: right;
  position: "relative";
}

#container {
  background-color: #dcdcdc;
}

.foot {
  border: 1px solid black !important;
  border-left: 0px !important;
  border-right: 0px !important;
  margin-bottom: 2rem;
  font-size: 1.6rem;
}

@media screen and (max-width: 600px) {
  .receipt-main {
    padding: 10px 15px !important;
    margin: 3px !important;
    width: 100vh !important;
    min-height: 70vh;
  }

  .footer__b {
    font-size: 1.2rem !important;
  }

  .foot {
    font-size: 1.2rem !important;
  }

}

#oopss {
  background: linear-gradient(-45deg, #22C1B7 #22C1B7);
  /* overflow: auto; */
  min-height: 100vh;
  width: 100%;
  z-index: 9999;
}

#oopss #error-text {
  font-size: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Shabnam', Tahoma, sans-serif;
  color: #000;
  direction: rtl;
}

#oopss #error-text img {
  margin: 35px auto 20px;
  height: 342px;
}

#oopss #error-text span {
  position: relative;
  font-size: 1.3em;
  font-weight: 900;
  margin-bottom: 50px;
}

#oopss #error-text p.p-a {
  font-size: 19px;
  text-align: center;
  margin: 30px 0 15px 0;
}

#oopss #error-text p.p-b {
  font-size: 15px;
}

@font-face {
  font-family: Shabnam;
  src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.eot");
  src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.woff") format("woff"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.woff2") format("woff2"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: Shabnam;
  src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.eot");
  src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.woff") format("woff"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.woff2") format("woff2"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.ttf") format("truetype");
  font-weight: normal;
}

.power {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  width: 100%;
  padding: 0 2rem;
}

.power img {
  width: 10% !important;
  margin-right: 0;
}

.center {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
}

.method-2 {
  font-size: 20px;
  position: relative;
  color: #d4d4d4;
  display: inline-block;
}

.method-2:before {
  content: attr(data-text);
  position: absolute;
  color: #2FB5B0;
  clip: rect(0, 0, 100px, 0);
  -webkit-animation: fill-3 1.5s infinite;
  animation: fill-3 1.5s infinite;
}

@-webkit-keyframes fill-3 {
  50% {
    clip: rect(0, 350px, 100px, 0);
  }

  100% {
    clip: rect(0, 0, 100px, 0);
  }
}

@keyframes fill-3 {
  50% {
    clip: rect(0, 350px, 100px, 0);
  }

  100% {
    clip: rect(0, 0, 100px, 0);
  }
}


/* .flexy {
  min-height: 100vh;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white none repeat scroll 0 0;
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  padding: 40px 30px !important;
  position: relative;
} */



.trythis {
  background: rgba(0, 0, 0, 0.239);
  /* Center the container horizontally */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Use flexbox to center vertically */
  align-items: center;
}

.containerxz {
  width: 100%;
  /* Set the default width to 100% */
  margin: 0 auto;
  /* Center the container horizontally */
  display: flex;
  /* Use flexbox to center vertically */
  align-items: center;
  /* Center the container vertically */
}

/* Media query for screens smaller than 768px (phone) */
@media (max-width: 767px) {
  .trythis {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .fle {
    padding: 0;
  }

  .this {
    padding: 0;
    margin: 0;
    width: 100vw;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
}

/* Media query for screens larger than 768px (laptop) */
@media (min-width: 768px) {
  .containerxz {
    width: 370px;
    /* Set the width to that of a standard phone */
    height: auto;
    border-radius: 12px;
    /* Allow the height to adjust according to the content */
  }

  .fle {
    padding: 0;
    border-radius: 12px;
  }
}

.css-w6hc5u {
  width: 46% !important;
}

.css-hdd9l7 {
  background-color: #00000042 !important;
}


@media (min-width: 768px) {

  .css-1o3pyl4,
  .css-16v2nb9,
  .css-xjhfu9,
  .css-1j63w8f,
  .css-1po1boe,
  .css-16hzqmq {
    width: 400px !important;
    left: 50% !important;
    position: absolute !important;
    transform: translateX(-50%) !important;
    animation-timing-function: ease-in-out !important;
    transition-duration: 2s !important;
  }

}


::before,
::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
}

button,
select {
  text-transform: none;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  color: inherit;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 6px solid #2FB5B0;
  border-radius: 50%;
  animation: lds-ring 2.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2FB5B0 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.row {
  margin-left: -15px !important;
  margin-right: -15px !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row:before,
.row:after {
  content: " " !important;
  display: table !important;
  box-sizing: border-box;
}

.row:after {
  clear: both !important;
}

[class*="col-"] {
  float: left !important;
  margin: 0 15px !important;
  /* Adjust this margin to control the gutter width */
}

p {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 6px;
  margin-left: 0px;
}

/* Responsive column styles for different screen sizes */
@media (max-width: 768px) {
  .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  [class*="col-"] {
    margin: 0 10px !important;
    /* Adjust this margin for smaller screens */
  }

  p {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 9px;
    margin-left: 0px;
  }
}

td {
  padding: 6px;
}

.css-1kwkhkr {
  margin-top: 10px !important;
}

.iframe-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #062a30ee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_complete_custom_btn {
  text-decoration: none;
  padding: 0.5rem;
  border: 1px solid #333333;
}

.css-1fjavwo {
  position: relative !important;
  height: 6.2em !important;
  width: fit-content !important;
  font-size: 8px !important;
  padding: 0 !important;
  font-weight: bold !important;
}

.css-xhfb4b {
  padding: 0px 8px !important;
  margin: 0 !important;
}

.css-gvm4fs {
  height: fit-content !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px !important;
}

.helper-text {
  color: red;
  font-size: 10px;
  padding: 0%;
  margin: 0%;
}

.periodInput {
  color: red;
  font-size: 10px;
  padding: 0%;
  margin: 0%;
  background-color: #000;
  width: fit-content !important;
}